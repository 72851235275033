<template>
	<section class="row">
		<div class="col-12">
			{{ type }}
		</div>
		<div class="col-12">
			<b-form-input
				ref="inputForm"
				v-model.lazy="inputValue"
				type="text"
				:disabled="disabled"
				:state="isRequiredAndFilled"
				@keypress="isNumber($event)"
				@blur="isInputActive = false"
				@focus="isInputActive = true"
			/>
		</div>
		<div class="col-auto" v-if="text">
			<p>{{ text }}</p>
		</div>
	</section>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'
export default {
	name: 'InputNumber',
	props: ['text', 'type', 'value', 'min', 'max', 'disabled', 'required', 'disableThousandSeparator'],
	mixins: [requiredFieldMixin],
	data() {
		return {
			isInputActive: false,
			}
	},
	mounted() {},
	methods: {
		isNumber(event) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',']
			const keyPressed = event.key
			if (!keysAllowed.includes(keyPressed)) {
				event.preventDefault()
			}
		},
	},
	watch: {
		value(val) {
			// Custom Validation - normally we can just use inbuilt validation for the number input with :min and :max
			// But in this case we have to use :text input to show the number with thousands seperator
			// So we have to implement our own custom validation
			var newVal = Number(val.toString().replace(/,/, '.'))
			if (newVal > this.max) {
				this.$refs['inputForm'].setCustomValidity('Indtastet tal er større end max')
				this.$refs['inputForm'].reportValidity()
			}
			// if (newVal < this.min) { // #502
			//   this.$refs["inputForm"].setCustomValidity(
			//     "Indtastet tal er mindre end mindst"
			//   );
			//   this.$refs["inputForm"].reportValidity();
			// }
			// if (newVal <= this.max && newVal >= this.min) {
			//   this.$refs["inputForm"].setCustomValidity("");
			// }
			if (newVal <= this.max) {
				this.$refs['inputForm'].setCustomValidity('')
			}
		},
	},
	computed: {
		inputValue: {
			get() {
				if (this.value && !this.disableThousandSeparator) {
					if (this.isInputActive) {
						return this.value + ''.toString().replace(/,/, '.')
					} else {
						var newVal = parseFloat(this.value).toLocaleString('da-DK')
						return newVal
					}
				} else return this.value
			},
			set(val) {
				var newVal = val.toString().replace(/,/, '.')
				this.$emit('input', Number(newVal))
			},
		},
	},
}
</script>

<style></style>